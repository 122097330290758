import TimeMe from "timeme.js";

const hpdIdleTimeoutInSeconds = 180;

var autoLogoutSet = false;
//idleTimeout is used for test only
window.setAutoLogout = function(timeoutInSeconds, idleTimeout) {
  if(autoLogoutSet && !idleTimeout){
    return;
  }
  
  if(!idleTimeout){
    idleTimeout = hpdIdleTimeoutInSeconds;
  }
  if(TimeMe.active){
    TimeMe.setIdleDurationInSeconds(idleTimeout)
  }else{
    TimeMe.initialize({
      idleTimeoutInSeconds: idleTimeout
    });
  }
  
  var logoutEvent;

  TimeMe.callWhenUserLeaves(function(){
    TimeMe.active = false;
    logoutEvent = setTimeout(function() {
      $.ajax({
        method: "DELETE",
        url: "/logout?auto=true"
      }).done(function( data ) {
        if(!data || !data.active){
          var minutes = timeoutInSeconds / 60;
          location.href = "/sign_in?timeout=" + minutes;
        }
      });
    }, timeoutInSeconds * 1000);
  });

  TimeMe.callWhenUserReturns(function(){
    TimeMe.active = true;
    if(logoutEvent){
      clearTimeout(logoutEvent);
    }
  });

  autoLogoutSet = true;
}

window.TimeMe = TimeMe;

window.initHPDPopover = function(event) {
    $('[data-toggle="popover"]').each(function () {
        var learn_more_url = $(this).data('learn-more-url');
        var template = `
            <div class="popover" role="tooltip">
            <div class="arrow"></div>
            <div class="popover-body"></div>
        `
        if(learn_more_url) {
            template += `<div class="learn-more"><a href="${learn_more_url}" target="_blank">Learn more.</a></div>`
        }
        template += '</div>'
        $(this).popover({
            html: true,
            template: template,
            content: function() {
                return $(this).data('content');
            }
        });
    });
}
import "jquery-validation";
import "jquery-validation/dist/additional-methods.js";

const addRegexValidator = function(value, regexp, attrName, message) {
  if(value && value != "" && !regexp.test(value)) {
    $.validator.messages[attrName] = message;
    return false;
  }
  return true;
}

window.validateHPDForm = function(dom, options) {
  dom.validate({
    ignore: '.ignore, .select2-input',
    onkeyup: false,
    focusInvalid: true,
    rules: options["rules"],
    // Errors
    errorPlacement: function errorPlacement(error, element) {
      var $parent = $(element).closest('.form-group');
      if ($parent.length == 0) {$parent = $(element).parents('p');}

      // Do not duplicate errors
      if ($parent.find('.jquery-validation-error').length) {
        return;
      }
      $parent.append(
        error.addClass('jquery-validation-error small form-text invalid-feedback')
      );
    },

    highlight: function(element) {
      var $el = $(element);
      var $parent = $el.closest('.form-group');
      if ($parent.length == 0) {$parent = $el.parents('p');}

      $el.addClass('is-invalid');
      // Select2 and Tagsinput
      if ($el.hasClass('select2-hidden-accessible') || $el.attr('data-role') === 'tagsinput') {
        $el.parent().addClass('is-invalid');
      }
    },

    unhighlight: function(element) {
      var $parent = $(element).closest('.form-group');
      if ($parent.length == 0) {$parent = $(element).parents('p');}
      $parent.find('.is-invalid').removeClass('is-invalid');
    }
  });
}

$.validator.addMethod("digits_only", function(value) {
  return addRegexValidator(value, /^\d+$/, 'digits_only', 'Please enter digits only.')
});

$.validator.addMethod("number_only", function(value) {
  return addRegexValidator(value, /^[\d\.]+$/, 'number_only', 'Please enter numbers only.')
});

$.validator.addMethod("date_check", function(value) {
  const isValidDate = function(dateString)
  {
      if(!/^\d{4}\-\d{1,2}\-\d{1,2}$/.test(dateString))
          return false;
  
      var parts = dateString.split("-");
      var year = parseInt(parts[0], 10);
      var month = parseInt(parts[1], 10);
      var day = parseInt(parts[2], 10);

      if(year < 1900 || year > (new Date()).getFullYear() || month == 0 || month > 12)
          return false;
  
      var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
          monthLength[1] = 29;
  
      return day > 0 && day <= monthLength[month - 1];
  };
  if(value == "" || isValidDate(value)) {
    return true;
  } else {
    $.validator.messages.date_check = "Please enter valid date."
    return false;
  }
});

$.validator.addMethod("pwcheck", function(value) {
  const reg = /(?=.*\d)(?=.*[\W])(?=.*[A-Z]).{7,}/;
  return addRegexValidator(value, reg, 'pwcheck', 'Passwords must include at least one uppercase letter, symbol, and number.')
});

$.validator.addMethod("phone_number_check", function(value) {
  // if sms flag is not checked, then empty phone is valid too.
  if (!$('#receive_notification_sms_flag').prop('checked') && value == '') {
    return true;
  }
  value = value.replace("+1", "")
  var tempValue = "+1" + value;
  var phoneNumber = parsePhoneNumberFromString(tempValue);
  if (phoneNumber) {
    if (phoneNumber.isValid() && phoneNumber.country === 'US') {
      return true;
    } else if (phoneNumber.country !== 'US') {
      $.validator.messages.phone_number_check = "Only US phone number is supported for now.";
      return false;
    } else {
      $.validator.messages.phone_number_check = "Please input a format like this: '(xxx) xxx-xxxx'";
      return false;
    }
  } else {
    $.validator.messages.phone_number_check = "Please input a valid US phone number in this format: '(xxx) xxx-xxxx'";
    return false;
  }
});

$.validator.addMethod("zipcode_check", function(value) {
  const reg = /^\d{5}(?:-\d{4})?$/;
  return addRegexValidator(value, reg, 'zipcode_check', 'Please provide a valid zipcode.')
});

$.validator.addMethod("email_format_check", function(value) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return addRegexValidator(value, reg, 'email_format_check', 'Please provide a valid email.')
});

$.validator.addClassRules("care_group_name_check", {
  required: true,
  remote:function(element) {
    return {
      url: "/site/care_groups/check_name",
      type: "post",
      data: {
        group_id: $(element).attr("id"),
        name: $(element).val()
      },
      dataFilter: function(data) {
        var json = JSON.parse(data);
        if(json.valid === true) {
          return true;
        }
        return "\"" + json.error + "\"";
      }
    };
  }
});

$.validator.addClassRules("pack_check", {
  required: true,
  remote:function(element) {
    return {
      url: "/patients/check_pack_token",
      type: "post",
      data: {
        token: function() {
          return $(element).val();
        }
      },
      dataFilter: function(data) {
        var json = JSON.parse(data);
        if(json.valid === true) {
          return true;
        }
        return "\"" + json.error + "\"";
      }
    };
  }
});

$.validator.addClassRules("email_check", {
  required: true,
  remote:function(element) {
    return {
      url: "/site/users/check_email",
      type: "post",
      data: {
        email: function() {
          return $(element).val();
        }
      },
      dataFilter: function(data) {
        var json = JSON.parse(data);
        if(json.valid === true) {
          return true;
        }
        return "\"" + json.error + "\"";
      }
    };
  }
});
import "bootstrap";
import "bootstrap-slider"

// Popovers
// Note: Disable this if you're not using Bootstrap's Popovers
$('[data-toggle="popover"]').popover();

$(document).on('click', function (e) {
  $('[data-toggle=popover]').each(function () {
    // hide any open popovers when the anywhere else in the body is clicked
    if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
      $(this).popover('hide');
    }
  });
});

// Tooltips
// Note: Disable this if you're not using Bootstrap's Tooltips
$('[data-toggle="tooltip"]').tooltip();

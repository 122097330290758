import "bootstrap-daterangepicker/daterangepicker.js";

window.initTimeRange = function(domId, yearly, hasRanges, maxSpan, requestData) {

  const start = moment($(`${domId}`).data("start-date"), 'YYYY-MM-DD');
  const end = moment($(`${domId}`).data("stop-date"), 'YYYY-MM-DD');

  function updateQueryString(params) {
    let url = window.location.href;

    Object.keys(params).forEach(function (key) {
      let val = params[key];
      let reExp = new RegExp("[\?|\&]" + key + "=[0-9a-zA-Z\_\+\-\|\.\,\;]*");
      if (reExp.test(url)) {
        // update
        let reExp = new RegExp("[\?&]" + key + "=([^&#]*)");
        let delimiter = reExp.exec(url)[0].charAt(0);
        url = url.replace(reExp, delimiter + key + "=" + val);
      } else {
        // add
        let newParam = key + "=" + val;
        if (!url.includes('?')) {
          url += '?' + newParam;
        } else {
          url += "&" + newParam;
        }
      }
    });

    if (history.state && history.state.url == url) {
      return false;
    } else {
      window.history.pushState({turbolinks: true, url: url}, "charts", url);
    }
  }

  function updateArrowsMeta(startDate, endDate) {
    let duration, previousStart, previousEnd, nextStart, nextEnd;

    if (startDate.format('YYYY-MM-DD') == startDate.clone().startOf('month').format('YYYY-MM-DD') && 
      endDate.format('YYYY-MM-DD') == endDate.clone().endOf('month').format('YYYY-MM-DD')) {
      duration = endDate.diff(startDate, 'months');
      previousStart = startDate.clone().subtract(duration + 1, 'month').startOf('month');
      previousEnd = endDate.clone().subtract(duration + 1, 'month').endOf('month');
      nextStart = startDate.clone().add(duration + 1, 'month').startOf('month');
      nextEnd = endDate.clone().add(duration + 1, 'month').endOf('month');
    } else {
      duration = endDate.diff(startDate, 'days');
      previousStart = startDate.clone().subtract(duration + 1, 'days');
      previousEnd = endDate.clone().subtract(duration + 1, 'days');
      nextStart = startDate.clone().add(duration + 1, 'days');
      nextEnd = endDate.clone().add(duration + 1, 'days');
    }

    $(".left-chevron").data("startDate", previousStart.format('YYYY-MM-DD'));
    $(".left-chevron").data("endDate", previousEnd.format('YYYY-MM-DD'));
    $(".right-chevron").data("startDate", nextStart.format('YYYY-MM-DD'));
    $(".right-chevron").data("endDate", nextEnd.format('YYYY-MM-DD'));
  }

  function callback(startDate, endDate, firstLoad) {
    let calendarLabel = 
      startDate.format('MMM DD, YYYY') + ' - ' + endDate.format('MMM DD, YYYY');
    const maxDate = moment().startOf('day');
    updateArrowsMeta(startDate, endDate);

    if(maxDate.isSame(endDate) || maxDate.isBefore(endDate)) {
      endDate = maxDate
      calendarLabel = startDate.format('MMM DD, YYYY') + ' - Today'
      $(".right-chevron").addClass("d-none")
    } else {
      $(".right-chevron").removeClass("d-none")
    }

    $(`${domId} span`).html(calendarLabel)
    
    if(!firstLoad){
      updateQueryString({
        start_date: startDate.format('YYYY-MM-DD'),
        stop_date: endDate.format('YYYY-MM-DD')
      });
    }
  }

  var options;
  if(yearly){
    var m = 2019;
    var years = {
      'All': [start, end]
    }
    while(m <= moment().year()){
      var s = moment().year() - m;
      years[m] = [moment().subtract(s, 'year').startOf('year'), moment().subtract(s, 'year').endOf('year')]
      m = m + 1;
    }

    options = {
      startDate: start,
      endDate: end,
      ranges: years,
      opens: "right",
      locale: {
        customRangeLabel: 'Custom'
      }
    };

  }else{
    if(hasRanges){
      options = {
        startDate: start,
        endDate: end,
        ranges: {
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        },
        maxSpan: {"days": maxSpan},
        opens: "right",
        maxDate: new Date(),
        locale: {
          customRangeLabel: `Custom (< ${maxSpan} days)`
        }
      };
    }else{
      options = {
        startDate: start,
        endDate: end,
        maxSpan: {"days": maxSpan},
        opens: "right"
      };
    }
  }

  $(domId).daterangepicker(options, callback);

  callback(start, end, true);
  requestData(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'), "Last 30 Days (default)");

  $(domId).on('apply.daterangepicker', function (ev, picker) {
    requestData(picker.startDate.format('YYYY-MM-DD'), picker.endDate.format('YYYY-MM-DD'), picker.chosenLabel);
  });

  $(".left-chevron, .right-chevron").on("click", function(e){
    const startDate = $(this).data("startDate");
    const endDate = $(this).data("endDate");
    const target = $(this)[0].className.includes("left-chevron") ? "Left Arrow" : "Right Arrow";
    
    requestData(startDate, endDate, target);
    callback(moment(startDate), moment(endDate));
  })
}
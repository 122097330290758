// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import '../stylesheets/application.scss'

global.$ = require("jquery");
import {} from 'jquery-ujs'

import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Turbolinks.start()
ActiveStorage.start()

require("../stylesheets/application.scss")
const images = require.context('../images', true)

import "modules/bootstrap";
import "modules/feather";
import "modules/moment";
import "modules/sidebar";
import "modules/toastr";
import "modules/user-agent";
import "modules/popover";
import "modules/highcharts";
import "modules/libphonenumber";
import "modules/daterangepicker";
import "modules/datetimepicker";
import "modules/mask";
import "modules/select2";
import "modules/validation";
import "modules/timeme";
import "modules/file-upload";
import "modules/cropper";
import "modules/jquery.are-you-sure"
import "modules/jquery.autocomplete.min"
import "modules/cookies"
import "modules/turbolinks"
import "modules/datatables";

// Global JS
import "modules/global";
import "modules/medical_device_charts";
import Highcharts from "highcharts";
import * as Xrange from 'highcharts/modules/xrange';
import * as Timeline from 'highcharts/modules/timeline';
import * as NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import more from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';

Xrange(Highcharts);
Timeline(Highcharts);
more(Highcharts);
NoDataToDisplay(Highcharts);
HighchartsExporting(Highcharts);

window.Highcharts = Highcharts;
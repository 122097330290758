var MedicalDeviceCharts = function () {
    var ChartColors = {
        chartBackground: '#f8f9fa',
        tooltipBorder: 'darkcyan',
        tooltipBackground: '#F6F6F6F2',
        threshold: '#AA4643',
        notification: '#AA4643',
        worn: '#B5CA92',
        logs: 'darkcyan',
        rr: '#77a1e5',
        hr: '#f45b5b',
        steps: '#FFB64F',
        baseline: '#3D96AE',
        normalRange: '#7cb5ec'
    }

    var ChartOptions = {
        warningIcon: null,
        hourChartsPath: null
    }
    var STEPS = 'Steps';
    var NO_DATA = 'No data';
    var WORN_HOURS = 'Worn hours';
    var PULSE_RATE_RANGE = 'Pulse rate range';
    var PULSE_RATE_DEVIATION = 'Pulse Rate Deviation';
    var RESPIRATORY_RATE_RANGE = 'Respiratory Rate Range';
    var DAILY_PERCENT_DEVIATION = 'Daily percent deviation';
    var RESPIRATORY_RATE_DEVIATION = 'Respiratory Rate Deviation';
    var LONG_TERM_RRT = 'Long Term Respiratory Rate Trends';
    var RESPIRATORY_RATE_BPM = 'Respiratory Rate (Breaths per minute)';
    var RR_DISTRIBUTION_MIN_TICK_INTERVAL = 86400000;

    var xAxisStartPoint = true;
    var xAxisEndPoint = true;
    var hasData = false;

    var setHighchartsConfig = function () {
        Highcharts.Point.prototype.highlight = function (event) {
            event = this.series.chart.pointer.normalize(event);

            this.onMouseOver();
            var tooltip = this.series.chart.tooltip;
            tooltip.shared ? tooltip.refresh([this]) : tooltip.refresh(this);

            this.series.chart.xAxis[0].drawCrosshair(event, this);
        };

        // Define custom series type for displaying low/med/high values using boxplot as a base
        Highcharts.seriesType('lowmedhigh', 'boxplot', {
            keys: ['low', 'median', 'high']
        }, {
            // Change point shape to a line with three crossing lines for low/median/high
            // Stroke width is hardcoded to 1 for simplicity
            drawPoints: function () {
                var series = this;
                this.points.forEach(function (point) {
                    var graphic = point.graphic,
                        verb = graphic ? 'animate' : 'attr',
                        shapeArgs = point.shapeArgs,
                        width = shapeArgs.width / 2,
                        left = Math.floor(shapeArgs.x) + width / 4 + 3,
                        right = left + width,
                        crispX = left + Math.round(width / 2) + 0.5,
                        highPlot = Math.floor(point.highPlot) + 0.5,
                        medianPlot = Math.floor(point.medianPlot) + 0.5,
                        lowPlot = Math.floor(point.lowPlot) + 0.5 - (point.low === 0 ? 1 : 0); // Sneakily draw low marker even if 0

                    if (point.isNull) {
                        return;
                    }

                    if (!graphic) {
                        point.graphic = graphic = series.chart.renderer.path('point').add(series.group);
                    }

                    graphic.attr({
                        stroke: point.color || series.color,
                        "stroke-width": 1
                    });

                    graphic[verb]({
                        d: [
                            'M', left, highPlot,
                            'H', right,
                            'M', left, medianPlot,
                            'H', right,
                            'M', left, lowPlot,
                            'H', right,
                            'M', crispX, highPlot,
                            'V', lowPlot
                        ]
                    });
                });
            }
        });
    }

    var initChartDom = function (container, title) {
        var dom = $('#' + container);
        dom.addClass('chart-container');
        var chartDomId = container + '_chart';
        dom.html(`<div class="chart-title">${title}</div><div id='${chartDomId}'></div>`);
        return chartDomId;
    }

    var insufOptions = {
        type: 'scatter',
        showInLegend: false,
        lineWidth: 0,
        marker: {
            enabled: false,
            states: {
                hover: {
                    enabled: false
                }
            }
        },
        dataLabels: {
            enabled: true,
            align: 'center',
            verticalAlign: 'middle',
            useHTML: true,
            formatter: function () {
                return ChartOptions.warningIcon;
            }
        }
    }

    var sharedOptions = {
        chart: {
            marginLeft: 50,
            spacingTop: 20,
            marginTop: 50,
            spacingBottom: 20,
            height: 200,
            events: {
                load: initHPDPopover
            }
        },
        title: {
            useHTML: true,
            align: 'left',
            margin: 5,
            x: 0,
            y: 3,
            style: { "fontWeight": "normal", "fontSize": "1rem" },
            text: null
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: true,
            margin: 8,
            itemMarginBottom: -12,
            symbolRadius: 0,
            symbolHeight: 9,
            itemStyle: { "fontWeight": "normal", "fontSize": "0.875rem" }
        },
        xAxis: {
            crosshair: true,
            type: 'datetime',
            dateTimeLabelFormats: {
                day: '%b %e'
            }
        },
        yAxis: {
            title: {
                text: null
            },
            gridLineDashStyle: 'shortDash'
        },
        exporting: {
            sourceWidth: 840,
            sourceHeight: 160,
            enabled: false
        },
        plotOptions: {
            series: {
                lineWidth: 1,
                animation: {
                    duration: 300
                },
                states: {
                    hover: {
                        lineWidthPlus: 0
                    },
                    inactive: {
                        opacity: 0.9
                    }
                },
                cursor: 'hand',
                events: {
                    click: function (event) {
                        event.point.series.chart.tooltip.hide();
                        $.get(ChartOptions.hourChartsPath, { date: getDateFromEvent(event), opened_from: event.point.series.name });
                    }
                }
            },
            line: {
                animation: false
            }
        },
        tooltip: {
            outside: true,
            animation: false,
            hideDelay: 0,
            headerFormat: '',
            shadow: false,
            borderColor: ChartColors.tooltipBorder,
            borderRadius: 5,
            backgroundColor: ChartColors.tooltipBackground,
            style: {
                width: 450,
                fontSize: '14px'
            },
            useHTML: true
        }
    }

    var linkInTooltipOptions = mergeDeep({
        tooltip: {
            style: {
                pointerEvents: 'auto'
            }
        }
    }, sharedOptions);
    delete linkInTooltipOptions.tooltip.outside;

    var highlightSeriesZero = function (domId, chart) {
        var eventTypes = ['mousemove', 'touchmove', 'touchstart'];
        eventTypes.forEach(function (eventType) {
            document.getElementById(domId).addEventListener(
                eventType,
                function (e) {
                    if (!chart.pointer) {
                        return
                    }
                    var event = chart.pointer.normalize(e);

                    var point_main = chart.series[0].searchPoint(event, true);
                    var point_baseline = chart.series[1].searchPoint(event, true);
                    var point_notification = null;

                    if (chart.series.length > 2) {
                        point_notification = chart.series[2].searchPoint(event, true);
                    }

                    if (point_main && point_baseline && point_baseline.x == point_main.x) {
                        if (point_notification && point_notification.x == point_baseline.x && event.chartY <= 45) {
                            point_notification.highlight(e);
                        } else {
                            point_main.highlight(e);
                        }
                    } else if (point_baseline) {
                        point_baseline.highlight(e);
                    }
                }
            );
        });
    };

    var notificationTooltip = function (event) {
        return `
        <table class="tooltip-table">
            <tr><th>Message:</th><td>${event.message}</td></tr>
            <tr><th>Notes:</th><td>${event.notes}</td></tr>
        </table>
    `
    }

    var devTooltipFormatter = function () {
        if (this.message) {
            return notificationTooltip(this);
        } else if (this.text) {
            return this.text;
        } else if (this.color === ChartColors.threshold) {
            return NO_DATA;
        } else {
            var tip;
            var link_name;
            if (this.show_tip) {
                if (this.tip_link_name == 'hr_deviation') {
                    link_name = ChartOptions.hrNotMet;
                } else {
                    link_name = ChartOptions.rrNotMet;
                }
                this.series.chart.tooltip.options.hideDelay = 3000
                tip = `<tr><th></th><td><span class="highlight">${ChartOptions.notMetContent}${link_name}</span></td></tr>`
            } else {
                this.series.chart.tooltip.options.hideDelay = 0
                tip = ''
            }

            return `
            <table class="tooltip-table">
                <tr><th>Deviation:</th><td>${this.y}%</td></tr>
                <tr><th>Out of Range:</th><td>${this.oor_hours} out of ${this.hours} 
                hours (${(this.oor_hours * 100 / this.hours).toFixed(1)}%)</td></tr>
                <tr><th>Date:</th><td>${new Date(this.x).toISOString().substring(0, 10)}</td></tr>
                ${tip}
            </table>
        `
        }
    }

    var rangeTooltipFormatter = function () {
        if (this.message) {
            return notificationTooltip(this);
        } else if (this.high) {
            return `
            <table class="tooltip-table">                
                <tr><th>High:</th><td>${this.high} bpm</td></tr>
                <tr><th>Median:</th><td>${this.median} bpm</td></tr>
                <tr><th>Low:</th><td>${this.low} bpm</td></tr>
                <tr><th>Date:</th><td>${new Date(this.x).toISOString().substring(0, 10)}</td></tr>
            </table>
        `
        } else {
            return NO_DATA
        }
    }

    var thresholdOptions = {
        type: 'spline',
        dashStyle: 'longdash',
        color: ChartColors.threshold,
        lineWidth: 1,
        marker: {
            enabled: false,
            states: {
                hover: {
                    enabled: false
                }
            },
        },
        enableMouseTracking: false
    }

    var notificationOptions = {
        name: 'Notifications',
        type: 'scatter',
        marker: {
            radius: 6,
            symbol: 'diamond'
        },
        color: ChartColors.notification,
        lineWidth: 0
    }

    var getDateFromEvent = function (event) {
        var date = new Date(0);
        date.setUTCSeconds(event.point.x / 1000);
        date = date.toISOString().substring(0, 10);
        return date;
    }

    var initializeChart = function (domId, chartData) {
        return Highcharts.chart(domId, mergeDeep(chartData, sharedOptions));
    }

    // Worn hours options
    var wornHoursOptions = function (params) {
        return {
            chart: {
                height: 160,
                borderRadius: 3
            },
            tooltip: {
                pointFormatter: function () {
                    if (this.y === 0 || this.color === ChartColors.threshold) {
                        return NO_DATA;
                    } else {
                        return `
                            <table class="tooltip-table">
                                <tr><th>Worn:</th><td>${this.y} hours</td></tr>
                                <tr><th>Date:</th><td>${new Date(this.x).toISOString().substring(0, 10)}</td></tr>
                            </table>
                        `;
                    }
                }
            },
            yAxis: {
                min: 0,
                max: 24,
                tickInterval: 12
            },
            series: [
                {
                    name: WORN_HOURS,
                    data: params.worn,
                    type: 'column',
                    color: ChartColors.worn
                },
                mergeDeep({
                    type: 'line',
                    name: `Goal (${params.goal || 0} hours )`,
                    data: params.goals
                }, thresholdOptions)
            ]
        }
    }

    var drawWornHoursChart = function (domID, params) {
        var wornHours = wornHoursOptions(params)
        var chartDomId = initChartDom(domID, 'Worn Hours');
        var wornHourChart = initializeChart(chartDomId, wornHours);
        highlightSeriesZero(chartDomId, wornHourChart);

        return wornHourChart;
    }

    var rrDeviationOptions = function (params) {
        var options = {
            tooltip: {
                pointFormatter: devTooltipFormatter,
                outside: true,
            },
            yAxis: {
                min: params.y_min,
                max: params.y_max,
                tickInterval: 10
            },
            series: [
                {
                    name: DAILY_PERCENT_DEVIATION,
                    data: params.data,
                    type: 'spline',
                    color: ChartColors.rr,
                    id: 'rrDataSeries'
                },
                mergeDeep({
                    name: `Threshold ( ${params.threshold || 0}% )`,
                    type: 'line',
                    data: params.threshold_series
                }, thresholdOptions),
                mergeDeep(
                    {
                        data: params.notifications
                    },
                    notificationOptions
                ),
                {
                    showInLegend: false, //dummy series to align spline with column
                    type: 'column'
                }
            ]
        }
        if (params.has_title) {
            options.title = { text: RESPIRATORY_RATE_DEVIATION }
        }
        if (params.bands) {
            options.xAxis = {
                plotBands: {
                    from: bands[0],
                    to: bands[1]
                }
            }
        }
        return options
    }

    var drawRrDeviationChart = function (domID, title, hasTooltip, params) {
        var baseOptions = (hasTooltip ? linkInTooltipOptions : sharedOptions)
        var chartDomId = initChartDom(domID, title);
        var devOptions = rrDeviationOptions(params);
        var rrDeviationChart = Highcharts.chart(chartDomId, mergeDeep(devOptions, baseOptions));
        highlightSeriesZero(chartDomId, rrDeviationChart);

        return rrDeviationChart;
    }

    var rrRangeOptions = function (params) {
        var options = {
            chart: {
                backgroundColor: ChartColors.chartBackground,
                borderRadius: 3,
                marginTop: 50
            },
            tooltip: {
                pointFormatter: rangeTooltipFormatter
            },
            yAxis: {
                min: params.y_min,
                max: params.y_max,
                tickInterval: 10
            },
            series: [
                {
                    name: RESPIRATORY_RATE_RANGE,
                    type: 'lowmedhigh',
                    color: ChartColors.rr,
                    data: params.data,
                    findNearestPointBy: 'x'
                },
                mergeDeep({
                    name: `Threshold ( ${params.threshold} bpm )`,
                    type: 'line',
                    data: params.threshold_series
                }, thresholdOptions),
                mergeDeep(
                    {
                        data: params.notifications
                    },
                    notificationOptions
                ),
                mergeDeep({
                    data: params.insuf
                }, insufOptions)
            ]
        }
        if (params.has_title) {
            options.title = { text: RESPIRATORY_RATE_RANGE }
        }
        if (params.bands) {
            options.xAxis = {
                plotBands: {
                    from: bands[0],
                    to: bands[1]
                }
            }
        }
        return options
    }

    var drawRrRangeChart = function (domID, title, params) {
        var chartDomId = initChartDom(domID, title);
        var rangeOptions = rrRangeOptions(params);
        var rrRangChart = initializeChart(chartDomId, rangeOptions);
        highlightSeriesZero(chartDomId, rrRangChart);

        return rrRangChart;
    }

    var hrDeviationOptions = function (params) {
        var options = {
            tooltip: {
                pointFormatter: devTooltipFormatter
            },
            yAxis: {
                min: params.y_min,
                max: params.y_max,
                tickInterval: 10
            },

            series: [
                {
                    name: DAILY_PERCENT_DEVIATION,
                    data: params.data,
                    type: 'spline',
                    color: ChartColors.hr
                },
                mergeDeep({
                    name: `Threshold ( ${params.threshold}% )`,
                    type: 'line',
                    data: params.threshold_series
                }, thresholdOptions),
                mergeDeep(
                    {
                        data: params.notifications
                    },
                    notificationOptions
                ),
                {
                    showInLegend: false, //dummy series to align spline with column
                    type: 'column'
                }
            ]
        }

        if (params.has_title) {
            options.title = { text: PULSE_RATE_DEVIATION }
        }
        if (params.bands) {
            options.xAxis = {
                plotBands: {
                    from: bands[0],
                    to: bands[1]
                }
            }
        }
        return options;
    }

    var drawHrDeviationChart = function (domID, title, hasTooltip, params) {
        var baseOptions = (hasTooltip ? linkInTooltipOptions : sharedOptions)
        var chartDomId = initChartDom(domID, title);
        var devOptions = hrDeviationOptions(params);
        var hrDeviationChart = Highcharts.chart(chartDomId, mergeDeep(devOptions, baseOptions));
        highlightSeriesZero(chartDomId, hrDeviationChart);

        return hrDeviationChart;
    }

    var hrRangeOptions = function (params) {
        var options = {
            chart: {
                backgroundColor: ChartColors.chartBackground,
                borderRadius: 3,
                marginTop: 50
            },
            tooltip: {
                style: {
                    width: 450
                },
                pointFormatter: rangeTooltipFormatter
            },
            yAxis: {
                min: params.y_min,
                max: params.y_max,
                tickInterval: 10
            },
            series: [
                {
                    name: PULSE_RATE_RANGE,
                    type: 'lowmedhigh',
                    color: ChartColors.hr,
                    data: params.data,
                    findNearestPointBy: 'x'
                },
                mergeDeep({
                    name: `Threshold ( ${params.threshold} bpm )`,
                    type: 'line',
                    data: params.threshold_series
                }, thresholdOptions),
                mergeDeep(
                    {
                        data: params.notifications
                    },
                    notificationOptions
                ),
                mergeDeep({
                    data: params.insuf
                }, insufOptions)
            ]
        }
        if (params.has_title) {
            options.title = { text: PULSE_RATE_RANGE }
        }
        if (params.bands) {
            options.xAxis = {
                plotBands: {
                    from: bands[0],
                    to: bands[1]
                }
            }
        }
        return options
    }

    var drawHrRangeChart = function (domID, title, params) {
        var chartDomId = initChartDom(domID, title);
        var rangeOptions = hrRangeOptions(params);
        var hrRangeChart = initializeChart(chartDomId, rangeOptions);
        highlightSeriesZero(chartDomId, hrRangeChart);

        return hrRangeChart;
    }

    var stepOptions = function (params) {
        var options = {
            chart: {
                height: 160
            },
            tooltip: {
                pointFormatter: function () {
                    if (this.message) {
                        return notificationTooltip(this);
                    } else if (this.y === 0 || this.color === ChartColors.threshold) {
                        return NO_DATA;
                    } else {
                        return `
                            <table class="tooltip-table">
                                <tr><th>Steps:</th><td>${this.y}</td></tr>
                                <tr><th>Date:</th><td>${new Date(this.x).toISOString().substring(0, 10)}</td></tr>
                            </table>
                        `;
                    }
                }
            },
            series: [
                {
                    name: STEPS,
                    data: params.data,
                    type: 'column',
                    color: ChartColors.steps
                },
                mergeDeep({
                    type: 'line',
                    name: `Goal ( ${params.goal} steps)`,
                    data: params.threshold_series
                }, thresholdOptions),
                mergeDeep(
                    {
                        data: params.notifications
                    },
                    notificationOptions
                )
            ]
        }
        return options;
    }

    var drawStepsChart = function (domID, params) {
        var chartDomId = initChartDom(domID, STEPS);
        var options = stepOptions(params)
        var stepsChart = initializeChart(chartDomId, options);
        highlightSeriesZero(chartDomId, stepsChart);

        return stepsChart;
    }

    var drawHourlyCharts = function (tz_offset, rr_title_suffix, hr_title_suffix, containers, params) {
        Highcharts.setOptions({
            global: {
                timezoneOffset: - tz_offset / 60
            }
        });

        var drawnCharts = {};
        var hourSharedOptions = mergeDeep({}, sharedOptions);
        delete hourSharedOptions.plotOptions.series.cursor;
        delete hourSharedOptions.plotOptions.series.events;

        var medianSvg = function (color) {
            return `<svg class="icon"><path fill="none" d="M 0 13 L 16 13" class="highcharts-graph" stroke="${color}" stroke-width="1"></path><path fill="${color}" d="M 8 17 A 4 4 0 1 1 8.003999999333336 16.99999800000017 Z" class="highcharts-point" opacity="1"></path></svg>`
        }

        var searchPoint = function (chart, seriesIndex, x) {
            var points = chart.series[seriesIndex].points;
            for (var i = 0; i < points.length; i++) {
                if (points[i].x == x) {
                    return points[i];
                }
            }
            return null;
        }

        var rangeTooltip = function () {
            var chart = this.series.chart;
            var devPoint = searchPoint(chart, 0, this.x);

            var bslPoint = searchPoint(chart, 1, this.x);
            var rangePoint = searchPoint(chart, 2, this.x);
            if (devPoint && devPoint.y) {
                this.series.chart.tooltip.options.hideDelay = 0
                if (bslPoint && bslPoint.y) {
                    var median = devPoint.y, baseline = bslPoint.y;
                    var deviation = ((median - baseline) * 100 / baseline).toFixed(2);
                    if (deviation >= 0) {
                        deviation = '+' + deviation;
                    }

                    var baselineSvg = `<svg class="icon"><path fill="none" d="M 0 13 L 16 13" class="highcharts-graph" stroke-dasharray="1,3" stroke="${ChartColors.baseline}" stroke-width="1"></path><path fill="${ChartColors.baseline}" d="M 8 10 L 11 13 8 16 5 13 Z" class="highcharts-point" opacity="1"></path></svg>`
                    var rangeSvg = `<svg class="icon"><rect x="3" y="8" width="8" height="8" fill="${ChartColors.normalRange}" class="highcharts-point"></rect></svg>`

                    return `
                    <table class="tooltip-table">
                        <tr><th>${medianSvg(devPoint.color)}</th><td class="name">${median} bpm</td><td rowspan="2"><span class="percent">${deviation}</span>%</td></tr>
                        <tr><th>${baselineSvg}</th><td class="name">${baseline} bpm</td></tr>
                        <tr><th>${rangeSvg}</th><td colspan="2" >${rangePoint.low} to ${rangePoint.high} bpm</td></tr>
                    </table>
                `
                } else {
                    return `
                    <table class="tooltip-table">
                        <tr><th>Median:</th><td>${devPoint.y} bpm</td></tr>
                    </table>
                `
                }
            } else {
                return false
            }
        }

        var baselineOptions = {
            name: 'Hourly baseline',
            type: 'spline',
            color: ChartColors.baseline,
            marker: {
                radius: 3,
                symbol: 'diamond'
            },
            dashStyle: 'Dot',
            enableMouseTracking: false
        }

        var rangeOptions = {
            name: 'Normal range',
            type: 'arearange',
            color: ChartColors.normalRange,
            fillOpacity: 0.2,
            showInLegend: true,
            marker: {
                radius: 3,
                symbol: 'diamond',
                enabled: false
            },
            linkedTo: ':previous',
            lineWidth: 0,
            enableMouseTracking: false
        }

        var wornMin = {
            chart: {
                backgroundColor: ChartColors.chartBackground,
                height: 160
            },
            title: {
                text: 'Worn Minutes'
            },
            tooltip: {
                pointFormatter: function () {
                    return this.y + ' minutes';
                }
            },
            yAxis: {
                min: 0,
                max: 60,
                tickInterval: 20
            },
            series: [
                {
                    name: 'Worn minutes',
                    data: params.data.worn_min,
                    type: 'column',
                    color: ChartColors.worn
                }
            ]
        }

        if (containers.worn) {
            drawnCharts.worn = Highcharts.chart(containers.worn, mergeDeep(wornMin, hourSharedOptions));
        }

        var rrMedian = {
            chart: {
                borderRadius: 3
            },
            title: {
                text: `Median Respiratory Rate${rr_title_suffix}`,
                useHTML: true
            },
            yAxis: {
                min: params.rr_median_min,
                max: params.rr_median_max,
                tickInterval: 2
            },
            tooltip: {
                formatter: rangeTooltip
            },
            series: [
                {
                    name: 'Hourly median',
                    data: params.data.rr_median,
                    type: 'spline',
                    color: ChartColors.rr
                },
                mergeDeep({
                    data: params.data.rr_baseline
                }, baselineOptions),
                mergeDeep({
                    data: params.data.rr_range
                }, rangeOptions),
                {
                    showInLegend: false,
                    type: 'column'
                }
            ]
        }

        if (containers.rr) {
            drawnCharts.rr = Highcharts.chart(containers.rr, mergeDeep(rrMedian, hourSharedOptions));
        }

        var hrMedian = {
            chart: {
                backgroundColor: ChartColors.chartBackground
            },
            title: {
                text: `Median Pulse Rate${hr_title_suffix}`,
                useHTML: true
            },
            tooltip: {
                formatter: rangeTooltip
            },
            yAxis: {
                min: params.hr_median_min,
                max: params.hr_median_max,
                tickInterval: 10
            },
            series: [
                {
                    name: 'Hourly median',
                    data: params.data.hr_median,
                    type: 'spline',
                    color: ChartColors.hr
                },
                mergeDeep({
                    data: params.data.hr_baseline
                }, baselineOptions),
                mergeDeep({
                    data: params.data.hr_range
                }, rangeOptions),
                {
                    showInLegend: false,
                    type: 'column'
                }
            ]
        }
        if (containers.hr) {
            drawnCharts.hr = Highcharts.chart(containers.hr, mergeDeep(hrMedian, hourSharedOptions));
        }

        var steps = {
            chart: {
                height: 160,
                borderRadius: 3
            },
            title: {
                text: STEPS
            },
            tooltip: {
                positioner: function (labelWidth, labelHeight, point) {
                    var chart = this.chart;
                    var plotLeft = chart.plotLeft;
                    var plotTop = chart.plotTop;
                    var tooltipX = point.plotX + plotLeft;
                    var tooltipY = point.plotY + plotTop;
                    var viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
                    var viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

                    tooltipX = Math.min(tooltipX, viewportWidth - labelWidth);
                    tooltipY = Math.min(tooltipY, viewportHeight - labelHeight);

                    return {
                        x: tooltipX,
                        y: tooltipY
                    };
                },
                pointFormatter: function () {
                    return this.y + ' ' + STEPS.toLowerCase();
                }
            },
            series: [
                {
                    name: STEPS,
                    data: params.data.steps,
                    type: 'column',
                    color: ChartColors.steps
                }
            ]
        }
        if (containers.steps) {
            drawnCharts.steps = Highcharts.chart(containers.steps, mergeDeep(steps, hourSharedOptions));
        }

        return drawnCharts;
    }

    var drawTrendChart = function (domID, messageDomID, showBaseline, params) {
        var colors = [
            '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce', '#492970',
            '#f28f43', '#77a1e5', '#c42525', '#a6c96a', '#4572A7', '#AA4643',
            '#89A54E', '#80699B', '#3D96AE', '#DB843D', '#92A8CD', '#A47D7C',
            '#B5CA92', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'
        ]

        var hourTag = function (hour) {
            if (hour >= 12) {
                return `${hour > 12 ? hour - 12 : hour} PM`
            } else {
                return `${hour} AM`
            }
        }

        var series = [];
        var keys = Object.keys(params.data).sort();
        for (var key in keys) {
            series.push({
                name: hourTag(key),
                data: params.data[key],
                type: 'line',
                color: colors[key]
            })
            if (showBaseline) {
                series.push({
                    data: params.baseline[key],
                    type: 'scatter',
                    color: '#444',
                    lineWidth: 0,
                    marker: {
                        radius: 3,
                        symbol: 'circle'
                    },
                    showInLegend: false
                })
            }
        }
        series.push({
            type: 'scatter',
            marker: {
                radius: 6,
                symbol: 'diamond'
            },
            color: '#AA4643',
            lineWidth: 0,
            data: params.notifications,
            showInLegend: false
        })

        var trendChart = new Highcharts.chart(domID, {
            chart: {
                marginLeft: 50,
                spacingTop: 20,
                spacingBottom: 20,
                height: 400,
                zoomType: 'x'
            },
            title: {
                text: LONG_TERM_RRT,
                style: { "fontWeight": "normal", "fontSize": "1rem" }
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: (Object.keys(params.data).length <= 6)
            },
            xAxis: {
                crosshair: true,
                type: 'datetime'
            },
            yAxis: {
                title: {
                    text: RESPIRATORY_RATE_BPM
                },
                min: 5,
                max: 35,
                gridLineDashStyle: 'shortDash'
            },
            plotOptions: {
                series: {
                    lineWidth: 1,
                    marker: {
                        radius: 2,
                        symbol: 'circle'
                    },
                    animation: {
                        duration: 300
                    },
                    states: {
                        hover: {
                            lineWidthPlus: 0
                        },
                        inactive: {
                            opacity: 0.9
                        }
                    }
                }
            },

            tooltip: {
                formatter: function () {
                    if (this.point.message) {
                        return `
                        <table class="tooltip-table">
                            <tr><th>Start At:</th><td>${this.point.start_at}</td></tr>
                            <tr><th>Stop At:</th><td>${this.point.stop_at}</td></tr>
                            <tr><th>Message:</th><td>${this.point.message}</td></tr>
                            <tr><th>Notes:</th><td>${this.point.notes}</td></tr>
                        </table>
                    `
                    } else {
                        return false;
                    }
                },
                useHTML: true,

                style: {
                    width: 450,
                    fontSize: '14px'
                }

            },
            noData: {
                style: { "fontWeight": "normal", "fontSize": "1rem" }
            },
            series: series
        });

        $('#' + messageDomID).html(params.message);
    }

    var drawRrDistributionChart = function (domID, title, tz_offset, series) {

        var rrDistributionOptions = {
            chart: {
                type: 'column',
                height: 250
            },
            tooltip: {
                formatter: function () {
                    var tbody = '';
                    var total = 0;

                    hasData = this.points.some(function (point) {
                        return point.y !== 0;
                    });


                    if (!hasData) {
                        tbody = `<tr><td>${NO_DATA}</td></tr>`;
                    } else {
                        this.points.reverse().forEach(function (point) {
                            if (point.y !== null) {
                                tbody += `
                                    <tr>
                                        <td>
                                            <div class='breath-band-block' style='background: ${point.series.color}'></div>
                                        </td>
                                        <td>${point.series.name}</td>
                                        <td class='secondary'><b>${point.percentage.toFixed(2)}%</b> (${point.y})</td>
                                    </tr>
                                `;
                                total += point.y
                            }
                        });

                        tbody += `<tr><td colspan="3" class='secondary'>Total: <b>${total.toLocaleString("en-US")}</b></td></tr>`
                        tbody += `<tr><td colspan="3" class='secondary'>Date: <b>${this.points[0].point.hour}</b></td></tr>`
                    }

                    var anchor = `<div class="tooltip-anchor-container"><div id="anchor" class="tooltip-anchor"></div></div>`;

                    return `<div class="tooltip-container">
                        ${anchor}
                        <table class="tooltip-table">${tbody}</table>
                    </div>`;
                },
                positioner: function (labelWidth, labelHeight, point) {
                    var chart = this.chart;
                    var chartPosition = chart.pointer.getChartPosition();
                    var xAxis = chart.xAxis[0];
                    var xAxisRange = xAxis.max - xAxis.min;
                    var minXValue = xAxis.min;
                    var maxXValue = xAxis.max;
                    var hoverPoints = chart.hoverPoints;
                    var yAxisHighestPoint = hoverPoints.sort((a, b) => a.plotY - b.plotY)[0];
                    var hoverPointX = point.plotX + chart.plotLeft;
                    var hoverPointValue = xAxis.toValue(hoverPointX);
                    var middlePointIndex = Math.floor(hoverPoints.length / 2);
                    var middlePoint = hoverPoints[middlePointIndex];
                    var anchorDiv = document.getElementById('anchor');
                    var tooltipMargin = this.chart.pointCount > 300 ? 0.06 : 0.04;
                    xAxisStartPoint = hoverPointValue < minXValue + xAxisRange * tooltipMargin;
                    xAxisEndPoint = hoverPointValue > maxXValue - xAxisRange * tooltipMargin;
                    var hideAnchor = hoverPointValue > maxXValue - xAxisRange * (tooltipMargin + 0.01) || hoverPointValue < minXValue + xAxisRange * (tooltipMargin + 0.01);
                    var xPos = chartPosition.left + middlePoint.barX + chart.plotLeft + middlePoint.pointWidth / 2 - labelWidth / 2 - 5;
                    yPos = chartPosition.top - labelHeight + 35;

                    if (hasData) {
                        if (hideAnchor && anchorDiv) {
                            anchorDiv.remove();
                        }
                        if (xAxisStartPoint || xAxisEndPoint) {
                            xPos = xAxisStartPoint
                                ? chartPosition.left + middlePoint.barX
                                : middlePoint.barX + chartPosition.left / 2;
                        }
                    }
                    return {
                        x: xPos,
                        y: yPos
                    };
                },
                shared: true
            },
            xAxis: {
                minTickInterval: RR_DISTRIBUTION_MIN_TICK_INTERVAL,
            },
            yAxis: {
                min: 0,
                reversedStacks: false,
                labels: {
                    enabled: false
                },
                gridLineWidth: 0
            },
            plotOptions: {
                column: {
                    stacking: 'percent',
                    borderColor: null,
                    groupPadding: 0,
                    pointPadding: 0
                },
                series: {
                    cursor: 'auto',
                    events: {
                        click: function (event) {
                        }
                    }
                },
            },
            time: {
                timezoneOffset: - tz_offset / 30
            },
            series: series
        }

        // <div class="custom-control custom-switch ml-3">
        //   <input type="checkbox" name="switch_band" id="switch_band" value="1" class="custom-control-input">
        //   <label class="custom-control-label" for="switch_band">High rate</label>
        // </div>
        title = `
                <div class="d-flex align-items-center">
                    ${title}
                </div>
                `
        var chartDomId = initChartDom(domID, title);
        var chart = Highcharts.chart(chartDomId, mergeDeep(rrDistributionOptions, sharedOptions));

        $('#switch_band').change(function () {
            chart.series.forEach(function (ser) {
                if (ser.visible) {
                    ser.update({ showInLegend: false, visible: false }, false)
                } else {
                    ser.update({ showInLegend: true, visible: true, animation: { duration: 500 } }, false)
                }
            })
            chart.redraw(true)
        })

        return chart;
    }

    return {
        colors: ChartColors,
        options: ChartOptions,
        initChartDom: initChartDom,
        sharedOptions: sharedOptions,
        drawWornHoursChart: drawWornHoursChart,
        drawRrDeviationChart: drawRrDeviationChart,
        drawRrRangeChart: drawRrRangeChart,
        drawHrDeviationChart: drawHrDeviationChart,
        drawHrRangeChart: drawHrRangeChart,
        drawStepsChart: drawStepsChart,
        drawHourlyCharts: drawHourlyCharts,
        drawTrendChart: drawTrendChart,
        drawRrDistributionChart: drawRrDistributionChart,
        setHighchartsConfig: setHighchartsConfig
    }
}

window.medicalDeviceCharts = MedicalDeviceCharts();
window.medicalDeviceCharts.setHighchartsConfig();

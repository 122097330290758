var spanSorting = '<span class="sorting"></span>',
    spanAsc = '<span class="sorting sorting_asc"></span>',
    spanDesc = '<span class="sorting sorting_desc"></span>';

window.replaceUrlParam = function(url, paramName, paramValue) {
  if (paramValue == null) {
    paramValue = '';
  }

  var pattern = new RegExp('\\b('+paramName+'=).*?(&|#|$)');
  if (url.search(pattern)>=0) {
    return url.replace(pattern,'$1' + paramValue + '$2');
  }

  url = url.replace(/[?#]$/,'');
  return url + (url.indexOf('?')>0 ? '&' : '?') + paramName + '=' + paramValue;
}

window.removeUrlParam = function(url, key) {
  var rtn = url.split("?")[0],
      param,
      params_arr = [],
      queryString = (url.indexOf("?") !== -1) ? url.split("?")[1] : "";
  if (queryString !== "") {
      params_arr = queryString.split("&");
      for (var i = params_arr.length - 1; i >= 0; i -= 1) {
          param = params_arr[i].split("=")[0];
          if (param === key) {
              params_arr.splice(i, 1);
          }
      }
      rtn = rtn + "?" + params_arr.join("&");
  }
  return rtn;
}

window.getUrlParameter = function(sParam) {
  var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
    }
  }
};

window.strEquals = function(str1, str2) {
  return typeof str1 === 'string' && typeof str2 === 'string' && str1.toUpperCase() === str2.toUpperCase();
}

window.initTableSorting = function() {
  $('[data-sort]').each(function(){
    $(this).addClass('has_sorting');

    var spanDom = $(this).find('span');
    if(spanDom.length == 0){
      $(this).append('<span class="sorting"></span>');
      spanDom = $(this).find('span')
    }

    var sortInParams = getUrlParameter('sort'),
        sortInElement = $(this).attr('data-sort'),
        orderInParams = getUrlParameter('order'),
        orderInElement = $(this).attr('data-order');

    if(sortInParams && strEquals(sortInParams, sortInElement)) {
      if(strEquals(orderInParams, 'asc')){
        spanDom.addClass('sorting_asc');
        spanDom.removeClass('sorting_desc');
      } else if(strEquals(orderInParams, 'desc')){
        spanDom.addClass('sorting_desc');
        spanDom.removeClass('sorting_asc');
      }
    } else if (sortInParams) {
      spanDom.removeClass('sorting_desc');
      spanDom.removeClass('sorting_asc');
    } else {
      if(strEquals(orderInElement, 'asc')){
        spanDom.addClass('sorting_asc');
        spanDom.removeClass('sorting_desc');
      } else if(strEquals(orderInElement, 'desc')){
        spanDom.addClass('sorting_desc');
        spanDom.removeClass('sorting_asc');
      }
    }

    $(this).off('click').on('click', function(){
      var currentUrl = window.location.href;

      var orderBy = 'asc';
      if($(this).find('span').hasClass('sorting_asc')){
        orderBy = 'desc';
      }

      var newUrl = replaceUrlParam(currentUrl, 'sort', sortInElement);
      newUrl = replaceUrlParam(newUrl, 'order', orderBy);
      // Reset to first page for new column or sort order
      newUrl = removeUrlParam(newUrl, 'page');
      if($(this).attr('sort-type')){
        newUrl += '&sort_type=' + $(this).attr('sort-type');
      }else{
        newUrl = removeUrlParam(newUrl, 'sort_type')
      }

      Turbolinks.visit(newUrl);
    });
  });
}
// this approach corrects the select 2 to be duplicated when clicking the back button.
$(document).on("turbolinks:before-cache", function () {
  $('.select2-hidden-accessible').select2('destroy');
});

$(document).on('ready turbolinks:load', function () {
  $(".select2").select2({
    minimumResultsForSearch: 20,
    dropdownAutoWidth: true,
    width: 'auto'
  });
  $(".select2_custom").select2({
    tags: true
  });

  $('[data-toggle="tooltip"]').tooltip();

  initTableSorting();
});

window.turboSubmit = function (form) {
  var path = form.action;
  var q = form.action.indexOf('?');
  if (q > 0) {
    path = path.substring(0, q)
  }
  Turbolinks.visit(path + '?' + $(form).serialize());
  return false;
}

window.remove_fields = function (link) {
  $(link)
    .prev("input[type=hidden]")
    .val("1");
  var $closeFormGroup = $(link).closest(".form-group");
  $closeFormGroup.find("input.check_pack").addClass("ignore");
  $closeFormGroup.removeClass("required").hide();
};

window.add_fields = function (link, association, content) {
  var new_id = new Date().getTime();
  var regexp = new RegExp("new_" + association, "g");
  $(link)
    .parent()
    .before(content.replace(regexp, new_id));
};

window.see_more = function (id) {
  $(".truncated-note-" + id).hide();
  $(".expanded-note-" + id).show();
};

window.see_less = function (id) {
  $(".expanded-note-" + id).hide();
  $(".truncated-note-" + id).show();
};

window.segmentTrackPageview = function (url, name, options) {
  $.ajax({
    method: "POST",
    url: url,
    dataType: "json",
    data: {
      name: name,
      options: options,
      screen: {
        width: $(window).width(),
        height: $(window).height()
      }
    },
    async: true
  });
};

window.inputChangeDelay = function (callback, ms) {
  var timer = 0;
  return function () {
    var context = this, args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      callback.apply(context, args);
    }, ms || 0);
  };
}

window.initPatientsAutoComplete = function (domID, serviceUrl, showSite) {
  $('#' + domID).autocomplete({
    serviceUrl: serviceUrl,
    width: 340,
    groupBy: 'category',
    showNoSuggestionNotice: true,
    formatResult: function (suggestion, currentValue) {
      var content = suggestion.data.highlight;
      var site = `<span class="badge badge-pill badge-gray">${suggestion.data.site}</span>`
      if (suggestion.data.category == 'NAME') {
        if (showSite) {
          content += `<div style="float:right">${site}</div>`;
        }
      } else {
        var rightContent = suggestion.value;
        if (showSite) {
          rightContent += site;
        }
        content += `<div style="float:right">${rightContent}</div>`
      }
      return content;
    },
    onSelect: function (suggestion) {
      Turbolinks.visit(`/patients/${suggestion.data.id}/notifications`);
    }
  });
}

window.initSitesAutoComplete = function (domID, serviceUrl) {
  $('#' + domID).autocomplete({
    serviceUrl: serviceUrl,
    width: 340,
    showNoSuggestionNotice: true,
    formatResult: function (suggestion, currentValue) {
      var content = suggestion.data.highlight;
      if (suggestion.data.short_code) {
        content += `<span class="badge badge-pill badge-gray">${suggestion.data.short_code}</span>`;
      }
      return content;
    },
    onSelect: function (suggestion) {
      Turbolinks.visit(`/portal/sites/${suggestion.data.id}`);
    }
  });
}

window.showLoading = function (message) {
  $('#modal-spinner .message').html(message);
  $('#modal-spinner').show();
}

window.hideLoading = function () {
  $('#modal-spinner').hide();
}

window.assessmentsSaved = function () {
  var valid = true;

  if ($('#visit_visit_type').length > 0) {
    $(".visits-save-error").show();
    valid = false
  } else {
    $(".visits-save-error").hide();
  }

  if ($('#medication_medication').length > 0) {
    $(".medications-save-error").show();
    valid = false
  } else {
    $(".medications-save-error").hide();
  }

  if ($('#equipment_name').length > 0) {
    $(".equipments-save-error").show();
    valid = false
  } else {
    $(".equipments-save-error").hide();
  }

  return valid;
}

window.validatePresence = function (elements, container, parentType) {
  for (const i of elements) {
    var d = container.find(i), p = d.closest(parentType);
    if (!d.val()) {
      if (!p.hasClass('is-invalid')) {
        p.addClass('is-invalid');
        p.append('<span class="error small">required</span>');
      }
      return false;
    } else {
      if (p.hasClass('is-invalid')) {
        p.removeClass('is-invalid');
        p.find('span:last').remove();
      }
    }
  }
  return true;
}

// Modal window
window.showPopup = function (title, body, actionName, options = {}) {
  const validationRules = options.validationRules || {};
  const hideCancel = options.hideCancel || false;
  const wideMode = options.wideMode || false;

  $('#popup').removeClass("half-modal");
  $('#minimize_modal').addClass('d-none');
  $('#half_minimize_modal').addClass('d-none');
  $('#half_to_maximize_modal').addClass('d-none');
  $('#maximize_modal').addClass('d-none');
  $(".modal-backdrop").addClass("show");

  $("#popup-modal").removeClass("modal-md");
  $("#popup-modal").removeClass("modal-wide");
  $("#popup-header").html(title);
  $("#popup-body").html(body);
  $("#popup-footer-action").prop('disabled', false);
  $("#popup-footer-action").html(actionName);
  $("#popup").modal("show");

  if (wideMode) {
    $("#popup-modal").addClass(wideMode == "modal-md" ? "modal-md" : "modal-wide");
  }
  if (options.width) {
    $("#popup-modal").css("width", options.width);
  }

  $("#popup-footer-back").hide();
  if (hideCancel) {
    $("#popup-footer-cancel").hide();
    $("#popup-footer-action").off().on("click", function () {
      $("#popup").modal("hide");
    });
  } else {
    $("#popup-footer-cancel").show();
    $("#popup-footer-cancel").off().on("click", function () {
      if (confirm("All changes will be discarded. Are you sure you want to cancel?")) {
        $("#popup").modal("hide");
      }
    });

    // submit form when clicking the button
    $("#popup-footer-action")
      .off()
      .on("click", function () {
        if (options.beforeSubmitCallback) {
          options.beforeSubmitCallback()
        }
        $("#popup-body form").submit();
      });
  }

  // validations for form
  if ($("#popup-body form")) {
    validateHPDForm($("#popup-body form"), validationRules);
  }

  document.onkeydown = function (evt) {
    evt = evt || window.event;
    var isEscape = false;
    if ("key" in evt) {
      isEscape = (evt.key === "Escape" || evt.key === "Esc");
    } else {
      isEscape = (evt.keyCode === 27);
    }
    if (isEscape) {
      $("#popup").modal("hide");
    }
  };
};
window.mergeDeep = function (target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        if (!target[key]) Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

window.isObject = function (item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}